import React from 'react'
import zenseimage1 from '../images/Zense/Zense_AviZense.webp'
import zenseimage2 from '../images/Zense/Zense_VisuaZense.svg'
import zenseimage3 from '../images/Zense/Zense_CogniZense.webp'
import whatiszense from '../images/Zense/Zense_WhatisZense.svg'
import logo1 from '../images/ubiz_logo.png'

const Zense = () => {
  return (
    <>
      <div className='container-fluid navigation_bar'>
        <nav
          className='navbar navbar-expand-lg fixed-top navbar-light'
          style={{ background: 'rgb(246, 246, 246)' }}
        >
          <a className='navbar-brand' href='./'>
            <img
              src={logo1}
              alt='logo1'
              style={{
                paddingLeft: '25px',
                paddingRight: '48px',
                width: '284px',
                height: '53px'
              }}
            />
          </a>
          <button
            className='navbar-toggler'
            aria-controls='navbarNavAltMarkup'
            aria-expanded='false'
            aria-label='Toggle navigation'
            data-bs-target='#navbarNavAltMarkup'
            data-bs-toggle='collapse'
            type='button'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='navbar-collapse collapse' id='navbarNavAltMarkup'>
            <div className='navbar-nav'>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-1 px-1'
                href='./'
              >
                Home
              </a>
              <a
                className='nav-button-dropdown nav-item nav-link active px-lg-3 px-sm-3 px-1'
                href='./zense'
              >
                Zense Solutions<span className='sr-only'>(current)</span>
              </a>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./enablingtechnologies'
              >
                Enabling Technologies
              </a>
              <li className='nav-item dropdown active'>
                <span
                  data-bs-target='#navbarNavAltMarkup'
                  aria-expanded='false'
                  className='nav-item nav-link toggle-collapse px-lg-3 px-sm-3 px-1'
                >
                  About Us
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='12'
                    height='12'
                    fill='currentColor'
                    className='bi bi-caret-down-fill'
                    viewBox='-2 1 16 16'
                  >
                    <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
                  </svg>
                  <ul
                    className='dropdown-menu collapse list-unstyled'
                    id='navbarNavAltMarkup'
                  >
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./about'
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./news'
                      >
                        News
                      </a>
                    </li>
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./career'
                      >
                        Career
                      </a>
                    </li>
                  </ul>
                </span>
              </li>

              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./contact'
              >
                Contact Us
              </a>
            </div>
          </div>
        </nav>
      </div>
      <section className='Background2 bg-light'>
        <section className='BackgroundForm'>
          <div className='container py-4' style={{ textAlign: 'center' }}>
            <h1 className='mb-4 py-3'>
              <span className='abouttext1'>From Sense to </span>
              <span className='abouttext2'>Zense</span>
            </h1>
            <p className='zensetext py-2'>
              IoT data is all around. Sense, as data, track the presence of
              things, and inconspicuously create traces. The mission of ubiZense
              is to bring senses to bear in your environment to react Zense - an
              intuitive calm state of mind. With Zense, you will have a sure
              foothold of trends in this data-dominant era, offering individuals
              and organizations to take actions with higher sensitivity and
              sustained confidence. UbiZense offers Zenselytics that tame and
              transform senses manifested in your working environment into
              pulses, which are intellectually juxtaposed with events of your
              corporate operations. Zenselytics are current, multifaceted,
              AI-assisted, on-demand analytics that address your needs to stay
              competitive and ahead. UbiZense cloud-based services seamlessly
              work with existing systems and IoT sensors networks.
            </p>
            <img
              className='zensecards py-2'
              src={whatiszense}
              alt='zensecards'
              width='85%'
            />
            <p className='zensetextt py-1 mb-3'>
              Zenselytics offers visibility analytics personalized to five
              cohesive perspectives, namely, View, Focus, Observe, Think, and
              Alert, with switchable fluidity to gain deep situational clarity
              and fidelity for rapid actionable decision.
            </p>
          </div>
          <h1 className='mb-4 py-3' style={{ textAlign: 'center' }}>
              <span className='abouttext1'>Our Solutions</span>
          </h1>
          <div className='container py-2 zenseform'>
            <img
              src={zenseimage1}
              alt='zenseimage1'
              style={{ width: 259.19, height: 259.19 }}
              className='zensecards'
            />
            <div className='py-4'>
              <h1 className='abouttext2 mb-3'>AviZense</h1>
              <p className='zensetext'>
                A dynamic solution reshaping ramp operations. Harnessing IoT and
                VA, our system effectively captures real-time data while cloud
                storage manages vast information volumes. Seamlessly integrating
                flight data and IoT insights, AviZense employs AI/ML for
                analyzing big and small data trends. Experience unparalleled
                efficiency in the new normal with our management dashboard and
                turnaround services. Transform your operations with AviZense,
                where cutting-edge technology drives intelligent
                decision-making.
              </p>
            </div>
          </div>
          <div className='container py-2 zenseform'>
            <img
              src={zenseimage2}
              alt='zenseimage2'
              style={{ width: 259.19, height: 259.19 }}
              className='zensecards'
            />
            <div className='py-4'>
              <h1 className='abouttext2 mb-3'>VisuaZense</h1>
              <p className='zensetext'>
                An innovative solution utilizing computer vision to seamlessly
                monitor operations and states. Our advanced system boasts RGB
                cameras for object, posture, and event detection, while stereo
                cameras add distance and dimensions for enhanced accuracy.
                Experience the power of LiDAR point cloud technology and thermal
                cameras for optimal precision. Step into the future with
                VisuaZense, where innovative computer vision technology
                revolutionizes detection and monitoring, elevating your
                processes to unparallel levels of efficiency and intelligence.
              </p>
            </div>
          </div>
          <div className='container py-2 zenseform'>
            <img
              src={zenseimage3}
              alt='zenseimage3'
              style={{ width: 259.19, height: 259.19 }}
              className='zensecards'
            />
            <div className='py-4'>
              <h1 className='abouttext2 mb-3'>CogniZense</h1>
              <p className='zensetext'>
                A revolutionary solution utilizing AI to deliver powerful
                predictive analytics. Our platform expertly transforms terabytes
                of structured and unstructured data into easily understandable
                management insights. With CogniZense, unlock the full potential
                of diverse data types for informed decision-making and optimized
                operations. Step into the future of intelligent decision-making,
                where AI-driven analytics and state-of-the-art technology join
                forces to elevate performance and propel success across various
                industries.
              </p>
            </div>
          </div>
          <div className='py-5' style={{ textAlign: 'center' }}>
            <p className='display-6 mb-2 py-5' style={{ color: 'gray' }}>
              Let's make 'sense' and reach 'zense'. Tell us about your projects and goals.
            </p>
            <div className='HomeButton py-2'>
              <button
                className='btn p-2 btn_custom1 col-lg-4 col-lg-4 col-6'
                style={{ marginBottom: '10px' }}
              >
                <a href='./contact'>
                  <span className='text-sec6a'>Contact Us</span>
                </a>
              </button>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}

export default Zense
