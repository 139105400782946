import React from 'react'
import twitterIcon from '../../images/products/icons8-twitter-circled.svg'
import linkedInIcon from '../../images/products/icons8-linkedin.svg'
import mailIcon from '../../images/products/icons8-circled-envelope-48.png'
import { MDBFooter } from 'mdb-react-ui-kit'

const Footer = () => {
  return (
    <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      <section className='border-bottom'></section>
      <div
        className='text-center p-2'
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
      >
        <div className='footericon py-2'>
          <a
            href='https://twitter.com/ubizense'
            target='_blank'
            rel='noreferrer'
          >
            <img src={twitterIcon} alt='twitterIcon' />
          </a>
          <a
            href='mailto:info@ubizense.com?subject=Apply%20for%20General%20Enquiry'
            target='_blank'
            rel='noreferrer'
          >
            <img src={mailIcon} alt='mailIcon' />
          </a>
          <a
            href='https://hk.linkedin.com/company/ubizense'
            target='_blank'
            rel='noreferrer'
          >
            <img src={linkedInIcon} alt='linkedInIcon' />
          </a>
        </div>
        © 2018-2024 {}
        啟悟 {}
        ubiZense, Ltd. All rights reserved.
        <br />
        Address: Office 05, 17/F, China Shipbuilding Tower, 650
        Cheung Sha Wan Road, Cheung Sha Wan, Kowloon, Hong Kong
        <br /> Email: info@ubizense.com
      </div>
    </MDBFooter>
  )
}

export default Footer
