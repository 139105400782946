import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'
import { TextField } from '@mui/material'
import Phone from '../images/phone.tsx'
import At from '../images/@.tsx'
import logo1 from '../images/ubiz_logo.png'
import { GoogleMap, LoadScript } from '@react-google-maps/api'

const containerStyle = {
  width: '620px',
  height: '310px'
}
const center = [
  {
    lat: 22.338769491858624,
    lng: 114.15230070718847
  },
  {
    lat: 22.262388042184835,
    lng: 114.13073643628451
  }
]
const ContactUs = () => {
  const form = useRef()

  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_zp8zhq8',
        'template_vfj897b',
        form.current,
        '0MZfEE_we92msH5X_'
      )
      .then(
        result => {
          console.log(result.text)
          console.log('message sent')
          e.target.reset()
        },
        error => {
          console.log(error.text)
        }
      )
  }

  return (
    <>
      <div className='container-fluid navigation_bar'>
        <nav
          className='navbar navbar-expand-lg fixed-top navbar-light'
          style={{ background: 'rgb(246, 246, 246)' }}
        >
          <a className='navbar-brand' href='./'>
            <img
              src={logo1}
              alt='logo1'
              style={{
                paddingLeft: '25px',
                paddingRight: '48px',
                width: '284px',
                height: '53px'
              }}
            />
          </a>
          <button
            className='navbar-toggler'
            aria-controls='navbarNavAltMarkup'
            aria-expanded='false'
            aria-label='Toggle navigation'
            data-bs-target='#navbarNavAltMarkup'
            data-bs-toggle='collapse'
            type='button'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='navbar-collapse collapse' id='navbarNavAltMarkup'>
            <div className='navbar-nav'>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-1 px-1'
                href='./'
              >
                Home
              </a>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./zense'
              >
                Zense Solution
              </a>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./enablingtechnologies'
              >
                Enabling Technologies
              </a>
              <li className='nav-item dropdown active'>
                <span
                  data-bs-target='#navbarNavAltMarkup'
                  aria-expanded='false'
                  className='nav-item nav-link toggle-collapse px-lg-3 px-sm-3 px-1'
                >
                  About Us
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='12'
                    height='12'
                    fill='currentColor'
                    className='bi bi-caret-down-fill'
                    viewBox='-2 1 16 16'
                  >
                    <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
                  </svg>
                  <ul
                    className='dropdown-menu collapse list-unstyled'
                    id='navbarNavAltMarkup'
                  >
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./about'
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./news'
                      >
                        News
                      </a>
                    </li>
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./career'
                      >
                        Career
                      </a>
                    </li>
                  </ul>
                </span>
              </li>

              <a
                className='nav-button-dropdown nav-item nav-link active px-lg-3 px-sm-3 px-1'
                href='./contact'
              >
                Contact Us<span className='sr-only'>(current)</span>
              </a>
            </div>
          </div>
        </nav>
      </div>
      <section className='Background6 bg-light'>
        <section className='BackgroundForm'>
          <section className='BackgroundNewss'>
            <div className='container py-4' style={{ textAlign: 'center' }}>
              <h1 className='mb-5'>
                <span className='abouttext2'>Contact Us</span>
              </h1>
            </div>
            <div className='row'>
              <div className='col'>
                <h2>Contact Information</h2>
                <div className='mb-3 py-4'>
                  <i style={{ color: '#FDB12A' }}>
                    <Phone width='22px' height='22px' />
                  </i>
                  <span className='icongap'>
                    <h5>+852 3957 9523</h5>
                  </span>
                </div>
                <div className='mb-2'>
                  <i style={{ color: '#FDB12A' }}>
                    <At width='22px' height='22px' />
                  </i>
                  <span className='icongap'>
                    <h5>info@ubizense.com</h5>
                  </span>
                </div>
                <div className='py-4'>
                  <h3 className='mb-4'>Cheung Sha Wan Office</h3>
                  <h5 className='mb-2'>
                    Office 05, 17/F, China Shipbuilding Tower,
                    <br /> 650 Cheung Sha Wan Road, Cheung Sha Wan, Kowloon,
                    <br />
                    Hong Kong
                  </h5>
                </div>
                <div>
                  <LoadScript googleMapsApiKey='AIzaSyDU2nzEOEAeVGbmY326JAcODFrWpmdqCUo'>
                    <GoogleMap
                      id='marker-example'
                      mapContainerStyle={containerStyle}
                      center={center[0]}
                      zoom={19}
                    ></GoogleMap>
                  </LoadScript>
                </div>

                <div className='py-4'>
                  <h3 className='mb-4'>Cyberport</h3>
                  <h5 className='mb-2'>
                    Smart Space 2, Unit 1205-1208,
                    <br /> Cyberport 2, 100 Cyberport Road,
                    <br />
                    Hong Kong
                  </h5>
                </div>
                <div>
                  <LoadScript googleMapsApiKey='AIzaSyDU2nzEOEAeVGbmY326JAcODFrWpmdqCUo'>
                    <GoogleMap
                      id='marker-example'
                      mapContainerStyle={containerStyle}
                      center={center[1]}
                      zoom={18}
                    >
                      {/* Child components, such as markers, info windows, etc. */}
                      <></>
                    </GoogleMap>
                  </LoadScript>
                </div>
              </div>
              <div className='col'>
                <h5 className='py-2'>Let's work together!</h5>
                <form ref={form} onSubmit={sendEmail}>
                  <div>
                    <TextField
                      label='Name'
                      type='text'
                      name='user_name'
                      required
                      margin='normal'
                      fullWidth
                    />
                  </div>
                  <div>
                    <TextField
                      label='Email'
                      type='email'
                      name='user_email'
                      required
                      margin='dense'
                      fullWidth
                    />
                  </div>
                  <div>
                    <TextField
                      label='Message'
                      name='message'
                      required
                      margin='dense'
                      fullWidth
                      multiline
                      rows={7}
                    />
                  </div>

                  <div className='HomeButton1 mb-4 py-3 float-end'>
                    <button
                      className='text-sec6a btn btn_custom1'
                      type='submit'
                      value='send'
                    >
                      Send a message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  )
}

export default ContactUs
