import React from 'react'
import Award1 from '../images/products/aws_unwire.png'
import Award2 from '../images/products/webnews.jpg'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import logo1 from '../images/ubiz_logo.png'

const News = () => {
  return (
    <>
      <div className='container-fluid navigation_bar'>
        <nav
          className='navbar navbar-expand-lg fixed-top navbar-light'
          style={{ background: 'rgb(246, 246, 246)' }}
        >
          <a className='navbar-brand' href='./'>
            <img
              src={logo1}
              alt='logo1'
              style={{
                paddingLeft: '25px',
                paddingRight: '48px',
                width: '284px',
                height: '53px'
              }}
            />
          </a>
          <button
            className='navbar-toggler'
            aria-controls='navbarNavAltMarkup'
            aria-expanded='false'
            aria-label='Toggle navigation'
            data-bs-target='#navbarNavAltMarkup'
            data-bs-toggle='collapse'
            type='button'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='navbar-collapse collapse' id='navbarNavAltMarkup'>
            <div className='navbar-nav'>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-1 px-1'
                href='./'
              >
                Home
              </a>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./zense'
              >
                Zense Solutions
              </a>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./enablingtechnologies'
              >
                Enabling Technologies
              </a>
              <li className='nav-item dropdown active'>
                <span
                  data-bs-target='#navbarNavAltMarkup'
                  aria-expanded='false'
                  className='nav-item nav-link toggle-collapse px-lg-3 px-sm-3 px-1'
                >
                  About Us
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='12'
                    height='12'
                    fill='currentColor'
                    className='bi bi-caret-down-fill'
                    viewBox='-2 1 16 16'
                  >
                    <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
                  </svg>
                  <ul
                    className='dropdown-menu collapse list-unstyled'
                    id='navbarNavAltMarkup'
                  >
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./about'
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item active'
                        href='./news'
                      >
                        News<span className='sr-only'>(current)</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./career'
                      >
                        Career
                      </a>
                    </li>
                  </ul>
                </span>
              </li>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./contact'
              >
                Contact Us
              </a>
            </div>
          </div>
        </nav>
      </div>
      <section className='Background4 bg-light'>
        <section className='BackgroundForm'>
          <section className='BackgroundNews'>
            <div
              className='container py-4 mb-2'
              style={{ textAlign: 'center' }}
            >
              <h1 className=' py-3'>
                <span className='abouttext2'>News</span>
              </h1>
            </div>
            <h1 className='latestnews'>Latest News</h1>
            <div className='row py-4'>
              <div className='col-6'>
                <Card className='card' style={{ maxWidth: '70%' }}>
                  <img
                    src={Award1}
                    alt='Award1'
                    style={{ marginTop: '-15px' }}
                  />
                </Card>
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    <a
                      href='https://unwire.pro/2022/11/15/aws-ubizense/feature/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      2022 Unwire Pro Feature
                    </a>
                  </Typography>
                  <Typography variant='body3' color='text.secondary'>
                    AWS 助 ubiZense 以 AI 處理停機坪大數據
                    有望縮減多達三成操作延誤
                  </Typography>
                </CardContent>
              </div>
              <div className='col-6'>
                <Card className='card' style={{ maxWidth: '70%' }}>
                  <img
                    src={Award2}
                    alt='Award2'
                    style={{ marginTop: '-15px' }}
                  />
                </Card>
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    <a
                      href='https://ezone.ulifestyle.com.hk/article/3384550?utm_source=ulweb&utm_medium=web?utm_source=ulweb&utm_medium=web'
                      target='_blank'
                      rel='noreferrer'
                    >
                      2022 ezone ulifestyle Feature
                    </a>
                  </Typography>
                  <Typography variant='body3' color='text.secondary'>
                    機場管理AIoT化（下） 採AWS雲端支援高速擴容
                  </Typography>
                </CardContent>
              </div>
            </div>
            <h1 className='latestnews mb-4'>Other News</h1>
            <Card>
              <div className='row py-4'>
                <div className='col'>
                  <ul style={{ fontSize: '18px' }}>
                    <p>
                      <b>2019-2022</b>
                    </p>
                    <li>
                      <a
                        href='https://worldaviationfestival.com/blog/airports/five-start-ups-to-look-out-for-at-aviation-festival-asia-maximising-efficiency-in-airport-operations-and-processes/'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        2022 World Avaiation Festival
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='http://www.takungpao.com.hk/231106/2022/1027/779989.html'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        2022 Ta Kung Pao
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='https://cyberport.hk/files/5f8ecb0f9dc12395523608/20201020_Press_Release_eng.pdf'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        2020 Cyberport Press Release
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='https://paper.hket.com/article/2377978/%E6%A9%9F%E7%AE%A1%E5%B1%80%E5%A4%A5%E5%88%9D%E5%89%B5%20AI%E4%BC%B0%E7%AE%97%E8%A1%8C%E6%9D%8E%E5%BB%B6%E8%AA%A4?mtc=40001&srkw=as2'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        HKET.com
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='https://skypost.ulifestyle.com.hk/article/2378000/%E6%A9%9F%E7%AE%A1%E5%B1%80%E5%96%84%E7%94%A8%E7%89%A9%E8%81%AF%E7%B6%B2%E7%B3%BB%E7%B5%B1%20%E6%B8%9B%E5%B0%91%E8%A1%8C%E6%9D%8E%E5%BB%B6%E8%AA%A4'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        Skypost
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://news.tvb.com/tc/local/5d07c48ae603839e52d32c63/%E6%A9%9F%E7%AE%A1%E5%B1%80%E6%99%BA%E8%83%BD%E5%8C%96%E7%B3%BB%E7%B5%B1%E7%AC%AC%E4%BA%8C%E9%9A%8E%E6%AE%B5%E7%A0%94%E7%99%BC-%E5%86%80%E9%80%B2%E4%B8%80%E6%AD%A5%E6%8F%90%E5%8D%87%E6%95%88%E7%8E%87'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        TVB
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='https://orientaldaily.on.cc/cnt/news/20190617/00176_039.html'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        Oriental Daily
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='https://unwire.hk/2019/06/27/hkict2019-interview-4/people-interview/ppl_interview/'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        unwire hk
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='https://www.singtao.ca/3539900/2019-06-17/post-%e6%a9%9f%e7%ae%a1%e5%b1%80ai%e7%89%a9%e8%81%af%e7%b6%b2-%e6%b8%9b%e5%b0%91%e8%a1%8c%e6%9d%8e%e5%bb%b6%e8%aa%a4%e6%a9%9f%e6%9c%83/?refer=toronto&variant=zh-hk'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        Sing Tao, Canada
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='https://www.hk01.com/%E7%A4%BE%E6%9C%83%E6%96%B0%E8%81%9E/341365/%E6%A9%9F%E7%AE%A1%E5%B1%80%E5%BC%95%E5%85%A5%E6%96%B0%E7%B3%BB%E7%B5%B1%E8%A1%8C%E6%9D%8E%E6%BA%96%E9%BB%9E%E7%8E%87%E9%81%9495-%E6%96%99%E4%B8%80%E5%B9%B4%E5%85%A7%E6%8E%A8%E5%AF%A6%E6%99%82%E8%A1%8C%E6%9D%8E%E6%8A%B5%E9%81%94%E6%99%82%E9%96%93'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        HK01
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='https://paper.hket.com/article/2377978/%E6%A9%9F%E7%AE%A1%E5%B1%80%E5%A4%A5%E5%88%9D%E5%89%B5%20AI%E4%BC%B0%E7%AE%97%E8%A1%8C%E6%9D%8E%E5%BB%B6%E8%AA%A4?mtc=40001&srkw=as2'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        經濟日報
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='https://hk.on.cc/hk/bkn/cnt/news/20190617/bkn-20190617003031101-0617_00822_001.html'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        東方日報
                      </a>{' '}
                    </li>
                  </ul>
                </div>
                <div className='col'>
                  <ul style={{ fontSize: '18px' }}>
                    <p>
                      <br />
                    </p>
                    <li>
                      <a
                        href='https://www.info.gov.hk/gia/general/201904/04/P2019040400524.htm?fontSize=1'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        HKSAR Press Releases
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='https://www.hkictawards.hk/award_en.php?year=2019'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        List of ICT Winners, 2019
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='https://www.hkictawards.hk/award_en.php?year=2019&aid=7'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        2019 Smart Mobility Grand Award (HKICT Awards Site)
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='https://witsa.org/witsa-presents-2019-world-ict-award-winners/'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        2019 Public/Private Partnership Award (World ICT Award
                        Site)
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='https://www.gs1hk.org/SmartMobilityAward'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        2019 Smart Mobility Winnders (GS1HK)
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='https://cyberport.hk/files/5caab57b0bcc3083587792/20190407_press_eng_v2.pdf'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        Cyberport Press Release | Cyberport Press Release -
                        local copy, highlighted text.
                      </a>
                    </li>
                    <li>
                      <a
                        href='http://images.rimanggis.com/resource/campaign/57ce73e5c16f4b1daa71d0d3/attachment/1270004.html'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        16 Cyberport start-ups scooped 19 awards at the Hong
                        Kong ICT Awards 2019
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.hongkongairport.com/en/media-centre/press-release/2019/pr_1334'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        HKIA Press Release
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='https://www.cpr.cuhk.edu.hk/en/press/cuhk-members-receive-prestigious-hong-kong-ict-awards-2019/'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        CUHK (The Chinese University of Hong Kong) Press Release
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='row py-4'>
                <div className='col'>
                  <ul style={{ fontSize: '18px' }}>
                    <p>
                      <b>2018</b>
                    </p>
                    <li>
                      <a
                        href='https://m.mingpao.com/pns/%e6%b8%af%e8%81%9e/article/20181129/s00002/1543430734231/%e6%a9%9f%e6%a2%b0%e4%ba%ba%e5%b7%a1%e6%a9%9f%e5%a0%b4-%e7%9b%a3%e5%af%9f%e5%ae%a4%e6%ba%abwi-fi%e4%bf%a1%e8%99%9f'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        Mingpao (明報新聞網)
                      </a>{' '}
                    </li>
                    <li>
                      <a
                        href='https://www.scmp.com/news/hong-kong/hong-kong-economy/article/2175441/computer-generated-food-robot-chefs-and-no-people'
                        target='_blank'
                        rel='noreferrer'
                        className='newstext'
                      >
                        SCMP (South China Morning Post)
                      </a>{' '}
                    </li>
                  </ul>
                </div>
              </div>
            </Card>
          </section>
        </section>
      </section>
    </>
  )
}

export default News
