import React from 'react'
import { Card, CardHeader, CardContent, Typography } from '@mui/material'
import logo1 from '../images/ubiz_logo.png'

const CareerOpportunity = () => {
  return (
    <>
      <div className='container-fluid navigation_bar'>
        <nav
          className='navbar navbar-expand-lg fixed-top navbar-light'
          style={{ background: 'rgb(246, 246, 246)' }}
        >
          <a className='navbar-brand' href='./'>
            <img
              src={logo1}
              alt='logo1'
              style={{
                paddingLeft: '25px',
                paddingRight: '48px',
                width: '284px',
                height: '53px'
              }}
            />
          </a>
          <button
            className='navbar-toggler'
            aria-controls='navbarNavAltMarkup'
            aria-expanded='false'
            aria-label='Toggle navigation'
            data-bs-target='#navbarNavAltMarkup'
            data-bs-toggle='collapse'
            type='button'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='navbar-collapse collapse' id='navbarNavAltMarkup'>
            <div className='navbar-nav'>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-1 px-1'
                href='./'
              >
                Home
              </a>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./zense'
              >
                Zense Solutions
              </a>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./enablingtechnologies'
              >
                Enabling Technologies
              </a>
              <li className='nav-item dropdown active'>
                <span
                  data-bs-target='#navbarNavAltMarkup'
                  aria-expanded='false'
                  className='nav-item nav-link toggle-collapse px-lg-3 px-sm-3 px-1'
                >
                  About Us
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='12'
                    height='12'
                    fill='currentColor'
                    className='bi bi-caret-down-fill'
                    viewBox='-2 1 16 16'
                  >
                    <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
                  </svg>
                  <ul
                    className='dropdown-menu collapse list-unstyled'
                    id='navbarNavAltMarkup'
                  >
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./about'
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./news'
                      >
                        News
                      </a>
                    </li>
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item active'
                        href='./career'
                      >
                        Career<span className='sr-only'>(current)</span>
                      </a>
                    </li>
                  </ul>
                </span>
              </li>

              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./contact'
              >
                Contact Us
              </a>
            </div>
          </div>
        </nav>
      </div>
      <section className='Background5 bg-light'>
        <section className='BackgroundForm'>
          <section className='BackgroundNews'>
            <div className='container py-4' style={{ textAlign: 'center' }}>
              <h1 className='mb-4'>
                <span className='abouttext2'>Career Opportunity</span>
              </h1>
            </div>
            <div className='row'>
              <div className='col'>
                <Card>
                  <CardHeader
                    className='CardHeader'
                    variant='body1'
                    title='Software Architect'
                    action={
                      <>
                        <div className='btn btn-lg btn_custom btn_shadow1'>
                          Full Time
                        </div>
                      </>
                    }
                  />
                  <CardContent>
                    <Typography variant='body1'>
                      <h4>Responsibilites</h4>
                      <div className='careertext'>
                        <li>Design solutions to satisfy user needs</li>
                        <li>
                          Select technologies that are appropriate to solve the
                          problem
                        </li>
                        <li>
                          Manage projects on hand to ensure a timely completion
                        </li>
                      </div>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant='body1'>
                      <h4>Requirements</h4>
                      <div className='careertext'>
                        <li>Degree holder</li>
                        <li>3+ year experience</li>
                        <li>
                          Cloud solutions experience is preferred, e.g., Docker,
                          Kubernetes, GitLab, etc
                        </li>
                        <li>Large scale project experience is preferred</li>
                      </div>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <h6 className='float-start'>
                      <div className='careertext'>Ref No.: job19001</div>
                    </h6>
                    <div className='HomeButton1 mb-4 float-end'>
                      <button className='btn btn_custom1'>
                        <span>
                          <a
                            className='text-sec6a'
                            href='mailto:info@ubizense.com?subject=Apply%20for%20Software%20Engineer%20(job19002)'
                          >
                            Apply Now
                          </a>
                        </span>
                      </button>
                    </div>
                  </CardContent>
                </Card>
              </div>
              <div className='col'>
                <Card>
                  <CardHeader
                    className='CardHeader'
                    variant='body1'
                    title='Software Engineer'
                    action={
                      <>
                        <button className='btn btn-lg btn_custom btn_shadow1'>
                          Full Time
                        </button>
                      </>
                    }
                  />
                  <CardContent>
                    <Typography variant='body1'>
                      <h4>Responsibilites</h4>
                      <div className='careertext'>
                        <li>Develop web and mobile applications</li>
                        <li>
                          Manage applications to ensure their functionalities
                        </li>
                        <li>
                          Rapid prototyping to test new technologies and ideas
                        </li>
                      </div>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant='body1'>
                      <h4>Requirements</h4>
                      <div className='careertext'>
                        <li>Degree Holder</li>
                        <li>
                          Web / iOS / Android, Java, Node.js, MQTT, JSON
                          experience
                        </li>
                        <li>Docker development environment preferred</li>
                        <li>Fresh graduates are welcome to apply</li>
                      </div>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <h6 className='float-start'>
                      <div className='careertext'>Ref No.: job19002</div>
                    </h6>
                    <div className='HomeButton1 mb-4 float-end'>
                      <button className='btn btn_custom1'>
                        <span>
                          <a
                            className='text-sec6a'
                            href='mailto:info@ubizense.com?subject=Apply%20for%20Software%20Engineer%20(job19002)'
                          >
                            Apply Now
                          </a>
                        </span>
                      </button>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            <div className='row py-5'>
              <div className='col'>
                <Card>
                  <CardHeader
                    className='CardHeader'
                    variant='body1'
                    title='Cloud Services Engineer'
                    action={
                      <>
                        <button className='btn btn-lg btn_custom btn_shadow1'>
                          Full Time
                        </button>
                      </>
                    }
                  />
                  <CardContent>
                    <Typography variant='body1'>
                      <h4>Responsibilites</h4>
                      <div className='careertext'>
                        <li>
                          Develop cloud applications to support web and mobile
                          applications
                        </li>
                        <li>Manage cloud environment</li>
                        <li>
                          Rapid prototyping to test new technologies and ideas
                        </li>
                      </div>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant='body1'>
                      <h4>Requirements</h4>
                      <div className='careertext'>
                        <li>Degree holder</li>
                        <li>Open Stack/Docker and AWS experience</li>
                        <li>Node.js, JAVA, JSON, Linux experience preferred</li>
                        <li>Real-time application experience preferred</li>
                        <li>Fresh graduates are welcome to apply</li>
                      </div>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <h6 className='float-start'>
                      <div className='careertext'>Ref No.: job19003</div>
                    </h6>
                    <div className='HomeButton1 mb-4 float-end'>
                      <button className='btn btn_custom1'>
                        <span>
                          <a
                            className='text-sec6a'
                            href='mailto:info@ubizense.com?subject=Apply%20for%20Software%20Engineer%20(job19002)'
                          >
                            Apply Now
                          </a>
                        </span>
                      </button>
                    </div>
                  </CardContent>
                </Card>
              </div>
              <div className='col'>
                <Card>
                  <CardHeader
                    className='CardHeader'
                    variant='body1'
                    title='UI/UX Engineer'
                    action={
                      <>
                        <button className='btn btn-lg btn_custom btn_shadow1'>
                          Full Time
                        </button>
                      </>
                    }
                  />
                  <CardContent>
                    <Typography variant='body1'>
                      <h4>Responsibilites</h4>
                      <div className='careertext'>
                        <li>Design UI/UX for web and mobile applications</li>
                        <li>Design a visually attractive interface</li>
                        <li>Design a pleasant user experience</li>
                      </div>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant='body1'>
                      <h4>Requirements</h4>
                      <div className='careertext'>
                        <li>Degree holder</li>
                        <li>Passionate in UI/UX design</li>
                        <li>Understand users' needs</li>
                        <li>Design tool skills experience</li>
                        <li>IoT/big data visualization skills preferred</li>
                      </div>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <h6 className='float-start'>
                      <div className='careertext'>Ref No.: job19005</div>
                    </h6>
                    <div className='HomeButton1 mb-4 float-end'>
                      <button className='btn btn_custom1'>
                        <span>
                          <a
                            className='text-sec6a'
                            href='mailto:info@ubizense.com?subject=Apply%20for%20Software%20Engineer%20(job19002)'
                          >
                            Apply Now
                          </a>
                        </span>
                      </button>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            <div className='py-2' style={{ textAlign: 'center' }}>
              <p className='display-10'>
                *Feel free to contact us for more information related to the job
                listing above*
              </p>
            </div>
          </section>
        </section>
      </section>
    </>
  )
}

export default CareerOpportunity
