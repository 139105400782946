import React from 'react'
import Technologyimage1 from '../images/Enabling Technologies/Enabling Technologies_IoT.svg'
import Technologyimage2 from '../images/Enabling Technologies/Enabling Technologies_Cloud Computing.svg'
import Technologyimage3 from '../images/Enabling Technologies/Enabling Technologies_AI.svg'
import logo1 from '../images/ubiz_logo.png'

const EnablingTechnologies = () => {
  return (
    <>
      <div className='container-fluid navigation_bar'>
        <nav
          className='navbar navbar-expand-lg fixed-top navbar-light'
          style={{ background: 'rgb(246, 246, 246)' }}
        >
          <a className='navbar-brand' href='./'>
            <img
              src={logo1}
              alt='logo1'
              style={{
                paddingLeft: '25px',
                paddingRight: '48px',
                width: '284px',
                height: '53px'
              }}
            />
          </a>
          <button
            className='navbar-toggler'
            aria-controls='navbarNavAltMarkup'
            aria-expanded='false'
            aria-label='Toggle navigation'
            data-bs-target='#navbarNavAltMarkup'
            data-bs-toggle='collapse'
            type='button'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='navbar-collapse collapse' id='navbarNavAltMarkup'>
            <div className='navbar-nav'>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-1 px-1'
                href='./'
              >
                Home
              </a>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./zense'
              >
                Zense Solutions
              </a>
              <a
                className='nav-button-dropdown nav-item nav-link active px-lg-3 px-sm-3 px-1'
                href='./enablingtechnologies'
              >
                Enabling Technologies<span className='sr-only'>(current)</span>
              </a>
              <li className='nav-item dropdown active'>
                <span
                  data-bs-target='#navbarNavAltMarkup'
                  aria-expanded='false'
                  className='nav-item nav-link toggle-collapse px-lg-3 px-sm-3 px-1'
                >
                  About Us
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='12'
                    height='12'
                    fill='currentColor'
                    className='bi bi-caret-down-fill'
                    viewBox='-2 1 16 16'
                  >
                    <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
                  </svg>
                  <ul
                    className='dropdown-menu collapse list-unstyled'
                    id='navbarNavAltMarkup'
                  >
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./about'
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./news'
                      >
                        News
                      </a>
                    </li>
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./career'
                      >
                        Career
                      </a>
                    </li>
                  </ul>
                </span>
              </li>

              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./contact'
              >
                Contact Us
              </a>
            </div>
          </div>
        </nav>
      </div>
      <section className='Background3 bg-light'>
        <section className='BackgroundForm'>
          <div className='container py-4' style={{ textAlign: 'center' }}>
            <h1 className='mb-4 py-3'>
              <span className='abouttext1'>Enabling </span>
              <span className='abouttext2'>Technologies</span>
            </h1>
            <p className='zensetext py-5'>
              Unlock the potential of enabling technologies, harnessing IoT,
              cloud computing and AI/ML for enhanced performance. Embrace
              real-time monitoring, predictive insights, and personalized views,
              empowering informed decision-making across industries. Experience
              the future with our innovative, adaptable, and user-focused
              solutions, leveraging the power of the cloud.
            </p>
          </div>
          <div className='container enabletechform'>
            <img
              src={Technologyimage1}
              alt='Technologyimage1'
              className='enabletechimg'
              width='324'
              height='324'
            />
            <div className='IoTholder' style={{ paddingTop: '75px' }}>
              <h1 className='abouttext2'>IoT</h1>
              <p className='zensetext py-3' style={{ fontWeight: 'bold' }}>
                Maximize your organization's health by utilizing IoT sensors to
                automatically monitor activities with minimal human
                intervention. Benefit from existing IoT sensors like CCTV, GPS,
                and mobile phones for seamless integration. Our unintrusive
                approach ensures little to no additional touchpoints, while the
                mobile app requires no installation. Harness the power of IoT
                technology to efficiently gauge and enhance your organizational
                health, driving overall performance and success.
              </p>
            </div>
          </div>
          <div className='container enabletechform'>
            <img
              src={Technologyimage2}
              alt='Technologyimage2'
              className='enabletechimg'
              width='324'
              height='324'
            />
            <div className='IoTholder' style={{ paddingTop: '75px' }}>
              <h1 className='abouttext2'>Cloud Computing</h1>
              <p className='zensetext py-3' style={{ fontWeight: 'bold' }}>
                Experience the convenience of accessing data anywhere and
                anytime with our patented technology, designed to synergize IoT
                data and contextual information. Our cloud-agnostic solution
                ensures low deployment costs and high scalability and
                availability, adapting to your organization's needs. Unlock the
                potential of seamless data access, integration and boosting
                efficiency. Experience the future with our innovative,
                cost-effective, and easily scalable technology.
              </p>
            </div>
          </div>
          <div className='container enabletechform'>
            <img
              src={Technologyimage3}
              alt='Technologyimage3'
              className='enabletechimg'
              width='324'
              height='324'
            />
            <div className='IoTholder' style={{ paddingTop: '75px' }}>
              <h1 className='abouttext2'>AI/ML</h1>
              <p className='zensetext py-3' style={{ fontWeight: 'bold' }}>
                Discover the power of clear management insights at your
                fingertips, as our AI/ML-driven platform converts vast big data
                into actionable information. Benefit from descriptive analytics
                for real-time comprehension, predictive analytics for future
                projections, and prescriptive analytics for scenario analysis.
                Adapting to work with big or small data, our solution
                continually learns and evolves. Tailored to individual needs, we
                offer customized views for users, delivering pertinent insights
                that drive informed decision-making across various industries.
              </p>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}

export default EnablingTechnologies
