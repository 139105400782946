import React from 'react'
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import LanguageIcon from '@mui/icons-material/Language'
import logo1 from '../images/ubiz_logo.png'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import video from '../videos/as2.mp4'

const card1 = (
  <React.Fragment>
    <CardContent>
      <Typography variant='h5' component='div'>
        A Promising Start
      </Typography>
      <Typography sx={{ mb: 1.5 }} color='text.secondary'></Typography>
      <Typography variant='body2'>
        - Founded ubiZense, an innovative company dedicated to smart
        aviation solutions <br />
        - Joined Cyberport Incubation Programme, accelerating our growth and
        development
        <br />- Partnered with CUHK's Centre of Cyber Logistics, collaborating
        on cutting-edge research and technology
      </Typography>
    </CardContent>
  </React.Fragment>
)
const card2 = (
  <React.Fragment>
    <CardContent>
      <Typography variant='h5' component='div'>
        Recognized for Excellence
      </Typography>
      <Typography sx={{ mb: 1.5 }} color='text.secondary'></Typography>
      <Typography variant='body2'>
        - Launched Phase 1 of the Airfield Service System (AS2) project in
        collaboration with Airport Authority Hong Kong (AAHK), taking airport
        services to new heights. <br />
        - Won the esteemed HKICT Award for our innovative solutions in Smart
        Mobility. <br />- Secured the prestigious World ICT Award, further
        solidifying our position as a global leader.
      </Typography>
    </CardContent>
  </React.Fragment>
)
const card3 = (
  <React.Fragment>
    <CardContent>
      <Typography variant='h5' component='div'>
        Perseverance and Adaptation
      </Typography>
      <Typography sx={{ mb: 1.5 }} color='text.secondary'></Typography>
      <Typography variant='body2'>
        - Demonstrated resilience and adaptability in the face of global
        challenges, maintaining our commitment to innovation and excellence
      </Typography>
    </CardContent>
  </React.Fragment>
)
const card4 = (
  <React.Fragment>
    <CardContent>
      <Typography variant='h5' component='div'>
        Continued Growth and Success
      </Typography>
      <Typography sx={{ mb: 1.5 }} color='text.secondary'></Typography>
      <Typography variant='body2'>
        - Embarked on Phase 2 of the AS2 project with AAHK, enhancing airfield
        efficiency and safety <br />- Involved in projects with Hong
        Kong government departments, providing expertise in computer vision, AI,
        and machine learning to drive smart solutions
      </Typography>
    </CardContent>
  </React.Fragment>
)
const card5 = (
  <React.Fragment>
    <CardContent>
      <Typography variant='h5' component='div'>
        Preparing for the Future
      </Typography>
      <Typography sx={{ mb: 1.5 }} color='text.secondary'></Typography>
      <Typography variant='body2'>
        - Continued to grow and refine our business strategies, setting the
        stage for continued success and innovation <br />- Collaborated with
        AAHK on data analytics, unlocking valuable insights and driving
        data-driven decision-making
      </Typography>
    </CardContent>
  </React.Fragment>
)
const card6 = (
  <React.Fragment>
    <CardContent>
      <Typography variant='h5' component='div'>
        The Future is Now
      </Typography>
      <Typography sx={{ mb: 1.5 }} color='text.secondary'></Typography>
      <Typography variant='body2'>
        - Embarked on Phase 3 of the AS2 project with AAHK, driving the future
        of airfield service systems
      </Typography>
    </CardContent>
  </React.Fragment>
)

const About = () => {
  return (
    <>
      <div className='container-fluid navigation_bar'>
        <nav
          className='navbar navbar-expand-lg fixed-top navbar-light'
          style={{ background: 'rgb(246, 246, 246)' }}
        >
          <a className='navbar-brand' href='./'>
            <img
              src={logo1}
              alt='logo1'
              style={{
                paddingLeft: '25px',
                paddingRight: '48px',
                width: '284px',
                height: '53px'
              }}
            />
          </a>
          <button
            className='navbar-toggler'
            aria-controls='navbarNavAltMarkup'
            aria-expanded='false'
            aria-label='Toggle navigation'
            data-bs-target='#navbarNavAltMarkup'
            data-bs-toggle='collapse'
            type='button'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='navbar-collapse collapse' id='navbarNavAltMarkup'>
            <div className='navbar-nav'>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-1 px-1'
                href='./'
              >
                Home
              </a>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./zense'
              >
                Zense Solutions
              </a>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./enablingtechnologies'
              >
                Enabling Technologies
              </a>
              <li className='nav-item dropdown active'>
                <span
                  data-bs-target='#navbarNavAltMarkup'
                  aria-expanded='false'
                  className='nav-item nav-link toggle-collapse px-lg-3 px-sm-3 px-1'
                >
                  About Us
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='12'
                    height='12'
                    fill='currentColor'
                    className='bi bi-caret-down-fill'
                    viewBox='-2 1 16 16'
                  >
                    <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
                  </svg>
                  <ul
                    className='dropdown-menu collapse list-unstyled'
                    id='navbarNavAltMarkup'
                  >
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item active'
                        href='./about'
                      >
                        About<span className='sr-only'>(current)</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./news'
                      >
                        News
                      </a>
                    </li>
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./career'
                      >
                        Career
                      </a>
                    </li>
                  </ul>
                </span>
              </li>

              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./contact'
              >
                Contact Us
              </a>
            </div>
          </div>
        </nav>
      </div>
      <section className='Background1 bg-light'>
        <section className='BackgroundForm'>
          <div className='container py-4' style={{ textAlign: 'center' }}>
            <h1 className='mb-4 py-3'>
              <span className='abouttext1'>About </span>
              <span className='abouttext2'>ubiZense</span>
            </h1>
            <p className='zensetext'>
              啟悟 ubiZense ('you-be-zense'), or ubiquitous Zense, translated in
              Chinese as shown, is a determination to poke or actuate the
              cognitive minds within the capacities of an organization, or a
              netizen on the move. With Zense one is at the best in-the-moment
              consenus of what and how to feel and act with respectful
              conviction.
            </p>
            <Timeline position='alternate'>
              <TimelineItem>
                <TimelineOppositeContent color='text.secondary'>
                  2018
                </TimelineOppositeContent>
                <TimelineSeparator className='dot2018'>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className='card2018'>
                  {' '}
                  <Box sx={{ minWidth: 275 }}>
                    <Card variant='outlined'>{card1}</Card>
                  </Box>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='text.secondary'>
                  2019
                </TimelineOppositeContent>
                <TimelineSeparator className='dot2019'>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className='card2019'>
                  {' '}
                  <Box sx={{ minWidth: 275 }}>
                    <Card variant='outlined'>{card2}</Card>
                  </Box>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='text.secondary'>
                  2020
                </TimelineOppositeContent>
                <TimelineSeparator className='dot2020'>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className='card2020'>
                  {' '}
                  <Box sx={{ minWidth: 275 }}>
                    <Card variant='outlined'>{card3}</Card>
                  </Box>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='text.secondary'>
                  2021
                </TimelineOppositeContent>
                <TimelineSeparator className='dot2021'>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className='card2021'>
                  {' '}
                  <Box sx={{ minWidth: 275 }}>
                    <Card variant='outlined'>{card4}</Card>
                  </Box>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='text.secondary'>
                  2022
                </TimelineOppositeContent>
                <TimelineSeparator className='dot2022'>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className='card2022'>
                  {' '}
                  <Box sx={{ minWidth: 275 }}>
                    <Card variant='outlined'>{card5}</Card>
                  </Box>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color='text.secondary'>
                  2023
                </TimelineOppositeContent>
                <TimelineSeparator className='dot2023'>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className='card2023'>
                  {' '}
                  <Box sx={{ minWidth: 275 }}>
                    <Card variant='outlined'>{card6}</Card>
                  </Box>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
            <div
              className='container py-2 mb-2 d-flex zenseform'
              style={{ textAlign: 'left' }}
            >
              <ConnectingAirportsIcon sx={{ fontSize: 100 }} color='disabled' />
              <div className='py-3'>
                <p className='zensetext'>
                  The management team at ubiZense has extensive experience in
                  aviation, logistics, computing, and information systems. They
                  have worked with industry leaders and practitioners to develop
                  a platform-based, community-focused, service-oriented
                  collaborative environment to improve operational efficiency
                  and sustainability in the industry. ubiZense is now advocating
                  for the digitalization of airway bills to streamline air cargo
                  terminal operations and provide a verifiable digital
                  footprint. They have a related pending patent.
                </p>
              </div>
            </div>
            <div
              className='container py-2 mb-2 d-flex zenseform'
              style={{ textAlign: 'left' }}
            >
              <div className='py-4'>
                <p className='zensetext'>
                  The company has experience deploying IoT sensors in the
                  garment supply chain for end-to-end visibility and has a
                  cloud-driven visibility platform. They have also been involved
                  in advising on enabling visibility of operations in an
                  international airport and are familiar with general airport
                  operation issues and concerns.
                </p>
              </div>
              <CloudSyncIcon sx={{ fontSize: 100 }} color='disabled' />
            </div>
            <div
              className='container py-2 d-flex zenseform'
              style={{ textAlign: 'left' }}
            >
              <LanguageIcon sx={{ fontSize: 100 }} color='disabled' />
              <div className='py-4'>
                <p className='zensetext'>
                  The company has a long history of technology adoption, from
                  the early days of the World Wide Web to Web 3.0 technologies
                  such as Nodejs, NoSQL, mobile applications, machine learning,
                  and Big Data analytics. They have experience developing and
                  deploying these technologies in on-premise server-based
                  environments as well as private and public cloud environments.
                </p>
              </div>
            </div>
            <div
              className='container py-2 d-flex zenseform'
              style={{ textAlign: 'left' }}
            >
              <div className='py-4'>
                <a href={video}
                  target='_blank'
                  rel='noreferrer'
                  style={{textAlign: "center"}}
                >
                  A short video of our solution Airfield Service System (AS2) currently in use in the Hong Kong International Airport since December 2021.
                </a>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}

export default About
