import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap'
import Home from './pages/Home'
import About from './pages/About'
import Zense from './pages/Zense'
import EnablingTechnologies from './pages/Enabling Technologies'
import News from './pages/News'
import CareerOpportunity from './pages/Career Opportunity'
import ContactUs from './pages/Contact Us'
import Footer from './pages/common/Footer'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' Component={Home} />
          <Route exact path='/zense' Component={Zense} />
          <Route
            exact
            path='/enablingtechnologies'
            Component={EnablingTechnologies}
          />
          <Route exact path='/about' Component={About} />
          <Route exact path='/contact' Component={ContactUs} />
          <Route exact path='/news' Component={News} />
          <Route exact path='/career' Component={CareerOpportunity} />
          <Route exact path='/' Element={<Navigate to='/' />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default App
