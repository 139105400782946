import React from 'react'
import logo from '../images/Home Page/Home_ubiZense_logo.svg'
import logo1 from '../images/ubiz_logo.png'
import Section1image from '../images/Home Page/Home_iamge.svg'
import Avizense1 from '../images/Home Page/Home_Flight Management.svg'
import Avizense2 from '../images/Home Page/Home_Baggage Management.svg'
import Avizense3 from '../images/Home Page/Home_Cargo Management.svg'
import Avizense4 from '../images/Home Page/Home_Catering.svg'
import Avizense5 from '../images/Home Page/Home_Refuelling.svg'
import Avizense6 from '../images/Home Page/Home_Pushing.svg'
import feature4 from '../images/Home Page/Home_CCL logo_transparent.svg'
import feature2 from '../images/Home Page/Home_HKAA.svg'
import feature3 from '../images/Home Page/Home_AWS.svg'
import feature1 from '../images/Home Page/Home_CEDD.svg'
import award1 from '../images/products/WITSA.jpg'
import award2 from '../images/products/HKICT.png'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Cyberportimage1 from '../images/News/20201020_Press_Release_eng-1.png'
import Cyberportimage2 from '../images/News/20201020_Press_Release_eng-2.png'
import Cyberportimage3 from '../images/News/20201020_Press_Release_eng-2.png'
import Cyberportimage4 from '../images/News/20201020_Press_Release_eng-2.png'
import Worldaviationimage1 from '../images/News/World Aviation Festival-1.png'
import Worldaviationimage2 from '../images/News/World Aviation Festival-2.png'
import Worldaviationimage3 from '../images/News/World Aviation Festival-3.png'
import Awsunwireimage1 from '../images/News/AWS UNWIRE.PRO-1.png'
import Awsunwireimage2 from '../images/News/AWS UNWIRE.PRO-2.png'
import ezoneimage1 from '../images/News/ezone_ulifestyle-1.png'
import ezoneimage2 from '../images/News/ezone_ulifestyle-2.png'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))
function BootstrapDialogTitle (props) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
}
const Home = () => {
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const [open1, setOpen1] = React.useState(false)
  const handleClickOpen1 = () => {
    setOpen1(true)
  }
  const handleClose1 = () => {
    setOpen1(false)
  }
  const [open2, setOpen2] = React.useState(false)
  const handleClickOpen2 = () => {
    setOpen2(true)
  }
  const handleClose2 = () => {
    setOpen2(false)
  }
  const [open3, setOpen3] = React.useState(false)
  const handleClickOpen3 = () => {
    setOpen3(true)
  }
  const handleClose3 = () => {
    setOpen3(false)
  }
  return (
    <>
      <div className='container-fluid navigation_bar'>
        <nav
          className='navbar navbar-expand-lg fixed-top navbar-light'
          style={{ background: 'rgb(246, 246, 246)' }}
        >
          <a className='navbar-brand' href='./'>
            <img
              src={logo1}
              alt='navbar-brand'
              style={{
                paddingLeft: '25px',
                paddingRight: '48px',
                width: '284px',
                height: '53px'
              }}
            />
          </a>
          <button
            className='navbar-toggler'
            aria-controls='navbarNavAltMarkup'
            aria-expanded='false'
            aria-label='Toggle navigation'
            data-bs-target='#navbarNavAltMarkup'
            data-bs-toggle='collapse'
            type='button'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='navbar-collapse collapse' id='navbarNavAltMarkup'>
            <div className='navbar-nav'>
              <a
                className='nav-button-dropdown nav-item nav-link active px-lg-3 px-sm-1 px-1'
                href='./'
              >
                Home <span className='sr-only'>(current)</span>
              </a>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./zense'
              >
                Zense Solution
              </a>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./enablingtechnologies'
              >
                Enabling Technologies
              </a>
              <li className='nav-item dropdown active'>
                <span
                  data-bs-target='#navbarNavAltMarkup'
                  aria-expanded='false'
                  className='nav-item nav-link toggle-collapse px-lg-3 px-sm-3 px-1'
                >
                  About Us
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    class='bi bi-caret-down-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
                  </svg>
                  <ul
                    className='dropdown-menu collapse list-unstyled'
                    id='navbarNavAltMarkup'
                  >
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./about'
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./news'
                      >
                        News
                      </a>
                    </li>
                    <li>
                      <a
                        className='nav-button-dropdown dropdown-item'
                        href='./career'
                      >
                        Career
                      </a>
                    </li>
                  </ul>
                </span>
              </li>
              <a
                className='nav-button-dropdown nav-item nav-link px-lg-3 px-sm-3 px-1'
                href='./contact'
              >
                Contact Us
              </a>
            </div>
          </div>
        </nav>
      </div>
      <section className='sec_1'>
        <div className='header_height d-flex align-items-center'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-home1 col-md-6 col-lg-6 py-5'>
                <h1 className='fadeInUp-animation w-75 mx-auto'>
                  <strong>
                    From Sense <br />
                    to Zense <br />
                    With Services
                  </strong>
                </h1>
                <p className='fadeInUp-animation2 text-white lead w-75 mx-auto'>
                  Ubizense fuses unstructured IoT data intellectually with
                  corporate data, and creates an event scaffold across
                  functional units to strengthen the single source of truth,
                  especially in communal settings within, and/or across boundary
                  of industries
                </p>
                <div className='fadeInUpp-animation3 w-75 mx-auto py-2'>
                  <a href='./contact'>
                    <button className='btn btn-lg btn_custom btn_shadow'>
                      Contact Us for a Services Demo
                    </button>
                  </a>
                </div>
              </div>
              <div className='col-md-6 col-lg-6 '>
                <img
                  src={Section1image}
                  alt='homesec1iamge'
                  className='homesec1iamge'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='bg-grey'>
        <div className='mx-auto py-5'>
          <div className='mb-4 text-center'>
            <p className='text-sec2'>
              <img src={logo} alt='logo1' className='img-fluid navlogo1' />
              <span className='text-sec2a'>What is </span>
              <span className='text-sec2b'>ZENSE?</span>
            </p>
          </div>
          <div className='row gy-2 d-flex align-items-center justify-content-evenly mt-0 py-1'>
            <div className='text-center'>
              <div className='text-sec3'>
                <div className='text-sec3break'>
                  ubiZense / yoU-Be-Zense/, or ubiquitous Zense, and in
                  Cantonese as 啟悟,
                </div>
                <div className='text-sec3break'>
                  is a determination to poke or actuate the cognitive minds
                  within the capacities of an organization,
                </div>
                <div className='text-sec3break'>
                  or a netizen on the move. With Zense one is at the best
                  in-the-moment consenus of what and how to feel and
                </div>
                <div className='text-sec3break'>
                  act with respectful conviction. That is, ZENSE is a calm & clear state of mind.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='header_height d-flex align-items-center'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-home3 col-md-6 col-lg-6 py-1'>
                <div
                  className='display-5 mb-5 w-75 mx-auto'
                  style={{ maxWidth: 'fit-content' }}
                >
                  <p className='py-2'>
                    <span className='text-sec1'>AviZense</span>
                  </p>
                  <div className='grid mx-auto'>
                    <img src={Avizense1} alt='Avizense1' />
                    <img src={Avizense2} alt='Avizense2' />
                    <img src={Avizense3} alt='Avizense3' />
                    <img src={Avizense4} alt='Avizense4' />
                    <img src={Avizense5} alt='Avizense5' />
                    <img src={Avizense6} alt='Avizense6' />
                  </div>
                </div>
                <p className='display-6 mb-4 w-75 mx-auto'>Our Solution</p>
                <p className='w-75 mx-auto news-description'>
                  Discover our comprehensive solution, combining IoT, computer
                  vision, and AI-driven analytics for optimal performance.
                  Benefit from real-time monitoring using IoT sensors and
                  advanced computer vision technologies. Transform vast data
                  into actionable insights with powerful predictive analytics,
                  powered by AI. Experience intelligent decision-making with our
                  all-in-one solution, revolutionizing efficiency and elevating
                  operations across various industries.
                </p>
                <p className='w-75 mx-auto'>
                  <Button
                    size='large'
                    endIcon={<ArrowRightAltIcon />}
                    href='./zense'
                  >
                    Read More
                  </Button>
                </p>
              </div>
              <div className='col-home4 col-md-6 col-lg-6'></div>
            </div>
          </div>
        </div>
      </section>
      <section className='bg-grey py-4'>
        <p className='display-6 mb-5 py-2 text-center'>
          <span className='text-sec2b'>Partnerships</span>
        </p>
        <div className='row gy-3 mb-1 d-flex align-items-center justify-content-evenly'>
          <a
            href='https://www.cedd.gov.hk/'
            target='_blank'
            rel='noreferrer'
            style={{ maxWidth: '235px' }}
          >
            <img src={feature1} alt='feature1' className='image_cedd' />
          </a>
          <a
            className='image_a'
            href='https://www.hongkongairport.com/'
            target='_blank'
            rel='noreferrer'
            style={{ maxWidth: '235px' }}
          >
            <img src={feature2} alt='feature2' className='image_airport' />
          </a>
          <a
            className='image_a'
            href='https://aws.amazon.com/'
            target='_blank'
            rel='noreferrer'
            style={{ maxWidth: '140px' }}
          >
            <img src={feature3} alt='feature3' className='image_aws' />
          </a>

          <a
            href='http://www.aiscl.baf.cuhk.edu.hk/indexfcf6.html?rSite/Index&sysPageId=141127182330536'
            target='_blank'
            rel='noreferrer'
            style={{ maxWidth: '235px' }}
          >
            <img src={feature4} alt='feature4' className='image_ccl' />
          </a>
        </div>
      </section>
      <section className='sec_5'>
        <div className='mx-auto py-5'>
          <div className='mb-5 py-2 text-center'>
            <p className='display-6 mb-1'>
              <span className='text-sec5'>Recognitions</span>
            </p>
          </div>
          <div className='row gy-3 d-flex align-items-center justify-content-evenly'>
            <Card className='card' sx={{ maxWidth: 360 }}>
              <a
                href='./news'
                className='awardimage'
                sx={{ height: 200 }}
                style={{ height: 200 }}
              >
                <img src={award1} alt='award1' />
              </a>
              <CardContent>
                <Typography gutterBottom variant='h5' component='div'>
                  2019 World ICT Award
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  The IoT-Augmented AS2 (Airfield Service System) was presented
                  the Public/Private Partnership Award, one of the seven 2019
                  WITSA Global ICT Excellence Awards by the World Information
                  Technology and Services Alliance (WITSA) on October 9, 2019 in
                  Yerevan, Armenia.
                </Typography>
              </CardContent>
            </Card>
            <Card className='card' sx={{ maxWidth: 360 }}>
              <a
                className='awardimage'
                href='./news'
                sx={{ height: 200 }}
                style={{ height: 200 }}
              >
                <img src={award2} alt='award2' style={{ width: '78%' }} />
              </a>
              <CardContent>
                <Typography gutterBottom variant='h5' component='div'>
                  2019 HKICT Award
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  The IoT-Augmented AS2 (Airfield Service System), a
                  collaborative effort by Airport Authority Hong Kong, Center of
                  Cyber Logistics, AISCL, CUHK, and ubiZense, has won the Smart
                  Mobility Grand Award, and, the Smarat Mobility (Smart
                  Logistics) Gold Award this year (2019) in Hong Kong.
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>
      <section className='bg-grey'>
        <div className='d-flex justify-content-center align-items-center'>
          <div className='col-12 col-md-9 py-5 text-center'>
            <p className='display-6 mb-3 py-3'>
              <span className='text-sec6'>News</span>
            </p>
            <div className='draw-line mb-5'></div>
            <div className='row mb-5' style={{ textAlign: 'left' }}>
              <div className='col'>
                <p className='news-description'>December 07, 2022</p>
                <h3 className='news-header'>World Aviation Festival</h3>
                <p className='news-description'>
                  Five start-ups to look out for at Aviation Festival Asia:
                  Maximising efficiency in airport operations and processes
                </p>
                <Button
                  onClick={handleClickOpen1}
                  className='mb-3 py-2'
                  size='large'
                  endIcon={<ArrowRightAltIcon />}
                >
                  Continue Reading...
                </Button>
                <BootstrapDialog
                  onClose={handleClose1}
                  aria-labelledby='customized-dialog-title'
                  open={open1}
                >
                  <BootstrapDialogTitle
                    style={{ color: '#646464' }}
                    id='customized-dialog-title'
                    onClose={handleClose1}
                  >
                    World Aviation Festival Article
                  </BootstrapDialogTitle>
                  <DialogContent>
                    <img
                      src={Worldaviationimage1}
                      alt='Worldaviationimage1'
                      style={{ width: '100%' }}
                    />
                    <img
                      src={Worldaviationimage2}
                      alt='Worldaviationimage2'
                      style={{ width: '100%' }}
                    />
                    <img
                      src={Worldaviationimage3}
                      alt='Worldaviationimage3'
                      style={{ width: '100%' }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      href='https://worldaviationfestival.com/blog/airports/five-start-ups-to-look-out-for-at-aviation-festival-asia-maximising-efficiency-in-airport-operations-and-processes/'
                      style={{ color: '#f2911a ' }}
                    >
                      go to article
                    </Button>
                  </DialogActions>
                </BootstrapDialog>
              </div>
              <div className='col'>
                <p className='news-description'>November 15, 2022</p>
                <h3 className='news-header'>UNWIRE.PRO</h3>
                <p className='news-description'>
                  AWS 助 ubiZense 以 AI 處理停機坪大數據
                  有望縮減多達三成操作延誤
                </p>
                <Button
                  onClick={handleClickOpen2}
                  className='mb-3 py-2'
                  size='large'
                  endIcon={<ArrowRightAltIcon />}
                >
                  Continue Reading...
                </Button>
                <BootstrapDialog
                  onClose={handleClose2}
                  aria-labelledby='customized-dialog-title'
                  open={open2}
                >
                  <BootstrapDialogTitle
                    style={{ color: '#646464' }}
                    id='customized-dialog-title'
                    onClose={handleClose2}
                  >
                    UNWIRE.PRO Article
                  </BootstrapDialogTitle>
                  <DialogContent>
                    <img
                      src={Awsunwireimage1}
                      alt='Awsunwireimage1'
                      style={{ width: '100%' }}
                    />
                    <img
                      src={Awsunwireimage2}
                      alt='Awsunwireimage2'
                      style={{ width: '100%' }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      href='https://unwire.pro/2022/11/15/aws-ubizense/feature/'
                      style={{ color: '#f2911a ' }}
                    >
                      go to article
                    </Button>
                  </DialogActions>
                </BootstrapDialog>
              </div>
            </div>
            <div className='row mb-5' style={{ textAlign: 'left' }}>
              <div className='col'>
                <p className='news-description'>October 27, 2022</p>
                <h3 className='news-header'>ezone ulifestyle HK</h3>
                <p className='news-description'>
                  機場管理AIoT化（下） 採AWS雲端支援高速擴容
                </p>
                <Button
                  onClick={handleClickOpen3}
                  className='mb-3 py-2'
                  size='large'
                  endIcon={<ArrowRightAltIcon />}
                >
                  Continue Reading...
                </Button>
                <BootstrapDialog
                  onClose={handleClose3}
                  aria-labelledby='customized-dialog-title'
                  open={open3}
                >
                  <BootstrapDialogTitle
                    style={{ color: '#646464' }}
                    id='customized-dialog-title'
                    onClose={handleClose3}
                  >
                    ezone ulifestyle HK Article
                  </BootstrapDialogTitle>
                  <DialogContent>
                    <img
                      src={ezoneimage1}
                      alt='ezoneimage1'
                      style={{ width: '100%' }}
                    />
                    <img
                      src={ezoneimage2}
                      alt='ezoneimage2'
                      style={{ width: '100%' }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      href='https://ezone.ulifestyle.com.hk/article/3384550?utm_source=ulweb&utm_medium=web?utm_source=ulweb&utm_medium=web'
                      style={{ color: '#f2911a ' }}
                    >
                      go to article
                    </Button>
                  </DialogActions>
                </BootstrapDialog>
              </div>
              <div className='col'>
                <p className='news-description'>October 20, 2020</p>
                <h3 className='news-header'>Cyberport</h3>
                <p className='news-description'>
                  IBM Teams Up with Hong Kong Cyberport to Boost Digital
                  Transformation Efforts in Hong Kong
                </p>
                <Button
                  onClick={handleClickOpen}
                  className='mb-3 py-2'
                  size='large'
                  endIcon={<ArrowRightAltIcon />}
                >
                  Continue Reading...
                </Button>
                <BootstrapDialog
                  onClose={handleClose}
                  aria-labelledby='customized-dialog-title'
                  open={open}
                >
                  <BootstrapDialogTitle
                    style={{ color: '#646464' }}
                    id='customized-dialog-title'
                    onClose={handleClose}
                  >
                    Cyberport Article
                  </BootstrapDialogTitle>
                  <DialogContent>
                    <img
                      src={Cyberportimage1}
                      alt='Cyberportimage1'
                      style={{ width: '100%' }}
                    />
                    <img
                      src={Cyberportimage2}
                      alt='Cyberportimage2'
                      style={{ width: '100%' }}
                    />
                    <img
                      src={Cyberportimage3}
                      alt='Cyberportimage3'
                      style={{ width: '100%' }}
                    />
                    <img
                      src={Cyberportimage4}
                      alt='Cyberportimage4'
                      style={{ width: '100%' }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      href='https://cyberport.hk/files/5f8ecb0f9dc12395523608/20201020_Press_Release_eng.pdf'
                      style={{ color: '#f2911a ' }}
                    >
                      go to article
                    </Button>
                  </DialogActions>
                </BootstrapDialog>
              </div>
            </div>
            <div className='HomeButton'>
              <a href='./news'>
                <button className='btn btn_custom1'>
                  <span className='text-sec6a'>+ More News</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home
